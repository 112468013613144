import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../_services/alert.service';
import { Login } from './login.model';
import { LoginService } from './login.service';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private loginService: LoginService) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });

        // if (localStorage.getItem('currentUser')) {
        //     this.authService.doLogout();
        // }
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    tryLogin() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        // const value = {
        //     username: this.f.email.value,
        //     password: this.f.password.value,
        //     rememberMe: false
        // };
        const credentials = new Login(this.f.email.value, this.f.password.value, false);
        // this.authService.doLogin(value)
        //     .then(res => {
        //         this.setUserInStorage(res);
        //         localStorage.removeItem('currentLayoutStyle');
        //         let returnUrl = '/dashboard/sales';
        //         if (this.returnUrl) {
        //           returnUrl = this.returnUrl;
        //         }
        //         this.router.navigate([returnUrl]);
        //     }, err => {
        //         this.submitted = false;
        //         this.alertService.error(err.message);
        //     });
        this.loginService.login(credentials).subscribe({
            next: () => {
                if (!this.router.getCurrentNavigation()) {
                    // There were no routing during login (eg from navigationToStoredUrl)
                    // this.router.navigate(['']);
                    let returnUrl = '/dashboard/dashboard1';
                    if (this.returnUrl) {
                        returnUrl = this.returnUrl;
                    }
                    this.router.navigate([returnUrl]);
                }
            },
            error: () => { this.submitted = false; },
        });
    }

    setUserInStorage(res) {
        if (res.user) {
            localStorage.setItem('currentUser', JSON.stringify(res.user));
        } else {
            localStorage.setItem('currentUser', JSON.stringify(res));
        }
    }
}
