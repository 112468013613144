import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AccountService } from '../core/auth/account.service';
import { StateStorageService } from '../core/auth/state-storage.service';

@Injectable()
export class AuthGuard implements CanActivate {

    // constructor(private router: Router) { }

    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //     if (localStorage.getItem('currentUser')) {
    //         // logged in so return true
    //         return true;
    //     }

    //     // not logged in so redirect to login page with the return url
    //     this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    //     return false;
    // }
    constructor(private router: Router, private accountService: AccountService, private stateStorageService: StateStorageService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.accountService.identity().pipe(
            map(account => {
                if (account) {
                    const authorities = route.data['authorities'];

                    if (!authorities || authorities.length === 0 || this.accountService.hasAnyAuthority(authorities)) {
                        return true;
                    }

                    if (isDevMode()) {
                        console.error('User has not any of required authorities: ', authorities);
                    }
                    this.router.navigate(['accessdenied']);
                    return false;
                }

                this.stateStorageService.storeUrl(state.url);
                this.router.navigate(['/login']);
                return false;
            })
        );
    }
}
