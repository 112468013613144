import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from '../core/config/application-config.service';
import { Login } from '../login/login.model';

type JwtToken = {
  id_token: string;
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private http: HttpClient,
    private applicationConfigService: ApplicationConfigService) { }



  // Login
  // doLogin(value) {
  //   return new Promise<any>((resolve, reject) => {
  //     firebase
  //       .auth()
  //       .signInWithEmailAndPassword(value.email, value.password)
  //       .then(
  //         res => {
  //           resolve(res);
  //         },
  //         err => reject(err)
  //       );
  //   });
  // }

  // Logout
  doLogout() {
    return new Promise<void>((resolve, reject) => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('remember');
      localStorage.removeItem('authenticationToken');
      sessionStorage.removeItem('authenticationToken');
      resolve();

    });
  }

  getToken(): string {
    const tokenInLocalStorage: string | null = localStorage.getItem('authenticationToken');
    const tokenInSessionStorage: string | null = sessionStorage.getItem('authenticationToken');
    return tokenInLocalStorage ?? tokenInSessionStorage ?? '';
  }

  login(credentials: Login): Observable<void> {
    return this.http
      .post<JwtToken>(this.applicationConfigService.getEndpointFor('api/authenticate'), credentials)
      .pipe(map(response => this.authenticateSuccess(response, credentials.rememberMe)));
  }

  logout(): Observable<void> {
    return new Observable(observer => {
      localStorage.removeItem('authenticationToken');
      sessionStorage.removeItem('authenticationToken');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('remember');
      observer.complete();
    });
  }

  private authenticateSuccess(response: JwtToken, rememberMe: boolean): void {
    const jwt = response.id_token;
    if (rememberMe) {
      localStorage.setItem('authenticationToken', jwt);
      sessionStorage.removeItem('authenticationToken');
    } else {
      sessionStorage.setItem('authenticationToken', jwt);
      localStorage.removeItem('authenticationToken');
    }
  }
}
