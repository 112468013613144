import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IArticle } from '../article.model';
import { ArticleService } from '../service/article.service';

@Component({
  selector: 'app-article-view',
  templateUrl: './article-view.component.html',
  styleUrls: ['./article-view.component.css']
})
export class ArticleViewComponent {

  welcome: String = 'Eleven';
  id: number;
  article: IArticle | null = null;
  html: String;

  constructor(
    private route: ActivatedRoute,
    private service: ArticleService
  ) { }

  ngOnInit() {
    this.route.queryParams
      // .filter(params => params.category)
      .subscribe(params => {
        this.id = params.i;
        console.log(this.id);
        this.service.findHtml(this.id).subscribe({
          next: (res) => {
            this.article = res.body;
            this.html = res.body.html;
          },
          error: (err) => {
            this.html = 'Sorry! This content is not exist or no longer available.';
          }
        }
        );
      }
      );
  }
}
