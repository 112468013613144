import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { CmsAnhQuery } from 'src/app/_model/CmsAnhQuery';
import { CmsControllerService } from 'src/app/_services/cms.service';
import { IncrementNumber } from 'src/app/_services/number-increment.service';

export const EDITOR_VALUE_ACCESSOR: any = {
   provide: NG_VALUE_ACCESSOR,
   useExisting: forwardRef(() => HtmlEditorComponent),
   multi: true
};
@Component({
   selector: 'html-editor',
   templateUrl: './html-editor.component.html',
   styleUrls: ['./html-editor.component.css'],
   providers: [EDITOR_VALUE_ACCESSOR]
})
export class HtmlEditorComponent implements OnInit, OnChanges, ControlValueAccessor {
   @Input() formGroup: FormGroup;
   @Input() formControl: FormControl;
   @Input() controlName: string;
   id = "id";
   title = 'website-admin';
   editor = null;
   isDisabled = false;
   value = '';
   start = 1;
   cmsAnhFindParms = {
      moTa: '',
      queryOffset: 1,
      queryLimit: 10,
      orderBy: "ngayTao desc"
   }
   tab = 0;
   cmsAnhList = [];
   isChangeAnh = false;
   anhUpload: File;
   imageUrl = "";
   modalReference: NgbModalRef = null;
   uploadForm: FormGroup;
   submitted = false;
   total = 0;
   tinymceInitp = {
      height: 800,
      width: '100%',
      // menubar: true,
      menubar: true,
      menu: {
         custom: { title: 'Custom menu', items: 'basicitem nesteditem toggleitem' }
      },
      relative_urls: false,
      remove_script_host: true,
      plugins: [
         'advlist autolink lists link image charmap print preview anchor',
         'searchreplace visualblocks code fullscreen',
         'insertdatetime table paste code help wordcount',
         'imageselect',
         "media"
      ],
      // content_style:
      //    "@font-face { font-family: Muli-Regular; src: url(../../../../../assets/font/muli/Muli-Regular.ttf) format('truetype'); } * { font-family: Muli-Regular ; }  body { font-family: Muli-Regular; }",
      fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",
      font_formats:
         "Muli-Regular=Muli-Regular;",
      toolbar:
         'undo redo | formatselect | fontsizeselect | link image imageselect |\
         bold italic forecolor  backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | code | fontselect | table  | fullscreen | media ',
      file_picker_types: 'image',
   };
   @ViewChild("selectImageModal") selectImageModal: ElementRef;
   constructor(
      private apiCms: CmsControllerService,
      private modalService: NgbModal,
      incrementNumber: IncrementNumber,
      private formBuilder: FormBuilder,
   ) {
      this.id = "editor" + incrementNumber.increment();
   }

   ngOnInit(): void {

      this.createUploadForm();
      if (this.formGroup) {
         this.formGroup.valueChanges.subscribe(values => {
            let formValue = this.formGroup.getRawValue();
            let newValue = formValue[this.controlName];
            if (this.value !== newValue) {
               this.value = newValue;
               if (this.editor) {
                  this.editor.resetContent(this.value);
               }
            }
         });
      }
      if (this.formControl) {
         this.formControl.valueChanges.subscribe(value => {
            let newValue = this.formControl.value;
            if (this.value !== newValue) {
               this.value = newValue;
               if (this.editor) {
                  this.editor.resetContent(this.value);
               }
            }
         })
      }
   }

   //create form upload
   createUploadForm() {
      this.uploadForm = this.formBuilder.group({
         anhUrl: ["", Validators.required],
         moTaAnh: ["", Validators.maxLength(400)]
      })
   }
   // convenience getter for easy access to form fields
   get fUpload() { return this.uploadForm.controls; }
   ngOnChanges(changes: SimpleChanges) {
      if (this.formGroup) {
         let formValue = this.formGroup.getRawValue();
         let newValue = formValue[this.controlName];
         if (this.value !== newValue) {
            this.value = newValue;
            if (this.editor) {
               this.editor.resetContent(this.value);
            }
         }
         if (this.formGroup.controls[this.controlName]) {
            this.isDisabled = this.formGroup.controls[this.controlName].disabled
         } else {
            this.isDisabled = true;
         }
      }
      if (this.formControl) {
         let newValue = this.formControl.value;
         if (this.value !== newValue) {
            this.value = newValue;
            if (this.editor) {
               this.editor.resetContent(this.value);
            }
         }
      }
   }


   onEditorInit(initData: { editor: any; }) {
      this.editor = initData.editor;
      this.editor.resetContent(this.value);
      if (this.isDisabled) {
         this.editor.setMode('readonly');
      } else {
         this.editor.setMode('design');
      }
   }

   onEditorChange(event: any) {
      this.value = this.editor.getContent();
      this.onModelChange(this.value);
      this.onModelTouched();
      if (this.formGroup) {
         let patchValue = {};
         patchValue[this.controlName] = this.value;
         this.formGroup.patchValue(patchValue);
      }
      if (this.formControl) {
         this.formControl.setValue(this.value);
      }
   }

   @HostListener('window:onImageSelectClick', ['$event.detail'])
   onImageSelectClick(detail: { id: string; }) {
      if (detail.id && detail.id === this.id) {
         this.modalReference = this.modalService.open(this.selectImageModal,
            { size: 'lg', scrollable: true, ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false });
         this.getAnh();
         this.modalReference.result.then((result) => {
            this.editor.insertContent("<img src='" + result + "'/>");
         }, (reason) => {
            console.log('onImageSelectClick: ', reason);
         });
      }
   }

   search() {
      this.getAnh();
   }

   insertImage(imageSrc: any) {
      this.modalReference.close(imageSrc);
   }

   onModelChange: Function = () => {
   };

   onModelTouched: Function = () => {
   };

   writeValue(value: any): void {
      if (this.value !== value) {
         this.value = value;
         if (this.editor) {
            this.editor.resetContent(this.value);
         }
      }
   }

   registerOnChange(fn: Function): void {
      this.onModelChange = fn;
   }

   registerOnTouched(fn: Function): void {
      this.onModelTouched = fn;
   }

   setDisabledState(isDisabled: boolean): void {
      this.isDisabled = isDisabled;
      if (this.editor) {
         if (this.isDisabled) {
            this.editor.setMode('readonly');
         } else {
            this.editor.setMode('design');
         }
      }
   }

   //change tab
   changeTab(tab: number) {
      this.tab = tab;
   }

   onFileImageSelect(file: FileList) {
      this.isChangeAnh = true;
      this.anhUpload = file.item(0);
      //show image preview
      var reader = new FileReader();
      reader.onload = (event: any) => {
         this.imageUrl = event.target.result;
      }
      if (this.anhUpload && this.anhUpload != null) {
         reader.readAsDataURL(this.anhUpload);
      } else {
         this.imageUrl = null;
      }
   }

   //upload anh
   async UploadImage() {
      try {


         this.submitted = true;
         if (this.uploadForm.invalid) {
            return;
         }
         let imageObject = {
            loaiAnh: 2,
            ten: this.anhUpload.name,
            thuMuc: "images",
            tenFile: this.anhUpload.name,
            trangThai: 1,
            moTa: ""
         }
         this.fUpload.moTaAnh.value ? imageObject.moTa = this.fUpload.moTaAnh.value.trim() : delete imageObject.moTa;

         let res = await firstValueFrom(this.apiCms.createImageCms(imageObject));

         let anhId = res.result.uuid;
         let res2 = await firstValueFrom(this.apiCms.updateImage(anhId, this.anhUpload, 0));

         this.modalReference.close('/images/' + res2.result.tenFile);
         this.createUploadForm();
         this.imageUrl = null;
         this.submitted = false;
      } catch (error) {
         alert('Something wrong! please try again later');
      }
   }

   //next
   nextPage() {
      this.start = this.start + 10;
      this.cmsAnhFindParms.queryOffset = this.start;
      this.getAnh();
   }

   //pre
   prePage() {
      this.start = this.start - 10;
      this.cmsAnhFindParms.queryOffset = this.start;
      this.getAnh();
   }

   //lay danh sach anh da upload
   getAnh() {
      this.apiCms
         .query(this.cmsAnhFindParms)
         .subscribe({
            next: (res: HttpResponse<CmsAnhQuery>) => {
               this.cmsAnhList = res.body.results;
               this.total = res.body.rowCount;
            },
            error: () => {
               console.error('error');
            },
         });
   }
}
