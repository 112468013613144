<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
      <div class="content-header-left col-md-6 col-12">
        <h3 class="content-header-title d-inline-block">Create/Edit Article</h3>
      </div>
      <div class="content-header-right col-md-6 col-12">
        <button type="button" (click)="save()" class="btn btn-primary mr-1 float-md-right">
          <i class="la la-save"></i>&nbsp;<span>Save</span>
        </button>
        <button type="button" (click)="previousState()" class="btn mr-1 btn-secondary float-md-right">
          <i class="la la-close"></i>&nbsp;<span>Cancel</span>
        </button>
      </div>
    </div>

    <div class="content-body">
      <div class="row">
        <div class="col-md-12" *blockUI="'blockUI'; message: 'Loading'">
          <div class="container container-fluid bg-white p-2">
            <form name="editForm" role="form" novalidate (ngSubmit)="save()" [formGroup]="editForm">

              <div>
                <jhi-alert-error></jhi-alert-error>

                <div style="display: none;" class="row mb-3" *ngIf="editForm.controls.id.value !== null">
                  <input type="number" class="form-control" name="id" id="field_id" data-cy="id" formControlName="id"
                    [readonly]="true" />
                </div>

                <div class=" mb-2 form-group">
                  <label class="form-label" for="field_title">Title <i class="red">*</i></label>
                  <input type="text" class="form-control" name="title" id="field_title" data-cy="title"
                    formControlName="title" />
                  <div
                    *ngIf="editForm.get('title')!.invalid && (editForm.get('title')!.dirty || editForm.get('title')!.touched)">
                    <small class="form-text text-danger" *ngIf="editForm.get('title')?.errors?.required"> This field is
                      required. </small>
                    <small class="form-text text-danger" *ngIf="editForm.get('title')?.errors?.maxlength">
                      This field cannot be longer than 255 characters.
                    </small>
                  </div>
                </div>

                <div class="mb-2 form-group">
                  <label class="form-label" for="field_description">Description</label>
                  <textarea type="text" class="form-control" name="description" id="field_description"
                    data-cy="description" formControlName="description" rows="5"></textarea>
                  <div
                    *ngIf="editForm.get('description')!.invalid && (editForm.get('description')!.dirty || editForm.get('description')!.touched)">
                    <small class="form-text text-danger" *ngIf="editForm.get('description')?.errors?.maxlength">
                      This field cannot be longer than 500 characters.
                    </small>
                  </div>
                </div>

                <div class="mb-2 form-group">
                  <label for="avatar">Thumbnail Image</label>
                  <div class="col-sm-12 mb-1 text-center">
                    <img *ngIf="imageUrl" src="{{imageUrl}}" class="img-fluid img-thumbnail" style="max-width: 60%;">
                  </div>
                  <input id="file-input" type="file" #Image accept="image/*" class="form-control" name="image"
                    (change)="onFileImageSelect($event.target.files)">
                </div>

                <div class="mb-2">
                  <label class="form-label">Content<i class="red">*</i></label>
                  <html-editor [attr.disabled]="false" name="html" formControlName="html"
                    [formControl]="fArticle.html"></html-editor>
                </div>

              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>