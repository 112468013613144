<!DOCTYPE html>
<html>

<head>
  <meta charset='utf-8'>
  <meta name='viewport' content='width=device-width'>
  <title>Reprodutiva - Article</title>
  <style>
    body {
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      padding: 1em;
    }
  </style>
</head>

<body>
  <!-- <p class="text-right font-small-3"><i class="feather ft-clock"></i> {{article?.publishedDate | date:'short'}};
    &nbsp;<i class="feather ft-eye"></i>&nbsp;{{article?.view}}
  </p> -->
  <div [innerHtml]="html"></div>
</body>

</html>