<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
    </div>
    <div class="content-body"><section class="flexbox-container">
<div class="col-12 d-flex align-items-center justify-content-center">
    <div class="col-10 col-sm-6 col-md-6 col-lg-4 box-shadow-2 p-0">
        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
            <div class="card-header border-0">
                <div class="card-title text-center">
                    <img src="assets/images/logo/logo-dark.png" class="img-fluid" alt="branding logo">
                </div>
            </div>
            <div class="card-content font-size-large">
                <p class="card-subtitle line-on-side text-muted text-center font-size-large mx-2 my-1"><span>Login</span></p>
                <div class="card-body">
                    <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="tryLogin()">
                        <fieldset class="form-group position-relative has-icon-left">
                            <input type="text" formControlName="email" class="form-control font-size-large" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                             id="user-name" placeholder="Your Username" required>
                            <div class="form-control-position">
                                <i class="feather ft-user font-size-large"></i>
                            </div>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Username is required</div>
                            </div>
                        </fieldset>
                        <fieldset class="form-group position-relative has-icon-left">
                            <input type="password" placeholder="Enter Password" formControlName="password" class="form-control font-size-large" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                            <div class="form-control-position">
                                <i class="la la-key font-size-large"></i>
                            </div>
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                            </div>

                        </fieldset>
                        <div class="form-group row">
                            <div class="col-md-6 col-12 text-center text-sm-left">
                                <div class="custom-control custom-checkbox mb-1">
                                  <input type="checkbox" class="custom-control-input" name="remember-me" id="remember-me">
                                  <label class="custom-control-label" for="remember-me">Remember Me</label>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-outline-success btn-block font-size-large">
                          <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                          <i class="feather ft-unlock" *ngIf="!submitted"></i> Login
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</section>

    </div>
  </div>
</div>
