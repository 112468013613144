import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbCarouselConfig, NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgChartsModule } from 'ng2-charts';
import { AlertComponent } from './_directives/alert.component';
import { AuthGuard } from './_guards/auth.guard';
import { AlertService } from './_services/alert.service';
import { ChangelogComponent } from './changelog/changelog.component';
import { httpInterceptorProviders } from './core/interceptor';

// Routing
import { routing } from './app.routing';

// Components
import { AppComponent } from './app.component';

import { MenuSettingsConfig } from './_layout/settings/menu-settings.config';
import { SettingsModule } from './_layout/settings/settings.module';
import { ThemeSettingsConfig } from './_layout/settings/theme-settings.config';

import { FullLayoutNavbarComponent } from './_layout/header/full-layout-navbar/full-layout-navbar.component';
import { HeaderComponent } from './_layout/header/header.component';
import { HorizontalComponent as HeaderHorizontalComponent } from './_layout/header/horizontal/horizontal.component';
import { VerticalComponent as HeaderVerticalComponent } from './_layout/header/vertical/vertical.component';

import { FooterComponent } from './_layout/footer/footer.component';
import { NavigationComponent as AppNavigationComponent } from './_layout/navigation/navigation.component';

import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';

import { LoginComponent } from './login';

import { HorizontalnavComponent } from './_layout/navigation/horizontalnav/horizontalnav.component';
import { VerticalnavComponent } from './_layout/navigation/verticalnav/verticalnav.component';
import { NavbarService } from './_services/navbar.service';

// perfect scroll bar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// spinner
import { RouterModule } from '@angular/router';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BlockUIModule } from 'ng-block-ui';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HtmlEditorComponent } from 'src/app/shared/html-editor/html-editor.component';
import { BlockTemplateComponent } from './_layout/blockui/block-template.component';
import { BreadcrumbModule } from './_layout/breadcrumb/breadcrumb.module';
import { CustomizerComponent } from './_layout/customizer/customizer.component';
import { HorizontalCustomizerComponent } from './_layout/customizer/horizontal-customizer/horizontal-customizer.component';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { DeviceDetectorService } from './_services/device-detector.service';
import { MatchHeightModule } from './content/partials/general/match-height/match-height.module';
import { PartialsModule } from './content/partials/partials.module';
import { ArticleUpdateComponent } from './content/article/update/article-update.component';

@NgModule({
  imports: [
    BrowserModule,
    PartialsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatchHeightModule,
    BreadcrumbModule,
    NgbModule,
    FormsModule,
    routing,
    NgChartsModule,
    // Settings modules
    SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
    PerfectScrollbarModule,
    NgxSpinnerModule,
    LoadingBarRouterModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    }),
    EditorModule,
  ],
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    PrivateLayoutComponent,
    HeaderComponent,
    FullLayoutNavbarComponent,
    HeaderHorizontalComponent,
    HeaderVerticalComponent,
    FooterComponent,
    AppNavigationComponent,
    AlertComponent,
    LoginComponent,
    VerticalnavComponent,
    HorizontalnavComponent,
    CustomizerComponent,
    HorizontalCustomizerComponent,
    BlockTemplateComponent,
    FullLayoutComponent,
    ChangelogComponent,
    HtmlEditorComponent,
    ArticleUpdateComponent,
  ],
  providers: [
    AuthGuard,
    AlertService,
    NavbarService,
    DeviceDetectorService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig
    },
    NgbCarouselConfig,
    NgbModalConfig,
    httpInterceptorProviders,
  ],
  entryComponents: [
    BlockTemplateComponent
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})

export class AppModule {

}
