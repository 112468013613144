import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { createRequestOption } from 'src/app/core/request/request-util';
import { CmsAnhQuery } from '../_model/CmsAnhQuery';
import { BaseHttpService } from './base-http.service';
// import { Response,NguoiDung } from '../_models/types'
@Injectable({
   providedIn: 'root'
})
export class CmsControllerService {
   constructor(
      private baseHttp: BaseHttpService,
      private http: HttpClient
   ) { }
   //tao anh
   createImageCms(data: any): Observable<any> {
      return this.http.post<any>('/api/file/imagecms', data);
   }

   //update anh
   updateImage(id: any, file: File, isPng: any): Observable<any> {
      const data: FormData = new FormData();
      data.append('file', file);
      data.append('isPng', isPng);
      return this.baseHttp.postImage<any>(`/api/file/image/${id}`, data);
   }


   //tim kiem anh
   findImage(data: any): Observable<any> {
      return this.baseHttp.get<any>(`/api/file/imagecms`, data);
   };
   //delete anh
   // deleteImage(id: any): Observable<any> {
   //    return this.http.delete<any>(`/api/back_end/cms/cms_anh/auth/${id}`);
   // };

   query(req?: any): Observable<HttpResponse<CmsAnhQuery>> {
      const options = createRequestOption(req);
      return this.http
         .get<CmsAnhQuery>(`/api/file/imagecms`, { params: options, observe: 'response' })
         .pipe(map(res => { return res }));
   }








}
