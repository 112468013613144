import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CmsControllerService } from 'src/app/_services/cms.service';
import { IArticle } from '../article.model';
import { ArticleService } from '../service/article.service';
import { ArticleFormGroup, ArticleFormService } from './article-form.service';

@Component({
  selector: 'jhi-article-update',
  templateUrl: './article-update.component.html',
  styleUrls: ['./article-update.component.css']
})
export class ArticleUpdateComponent implements OnInit {
  isSaving = false;
  article: IArticle | null = null;
  html: string = '';
  editForm: ArticleFormGroup = this.articleFormService.createArticleFormGroup();
  anhTinTuc: File;
  isChangeImage = false;
  imageUrl = null;

  constructor(
    protected articleService: ArticleService,
    protected articleFormService: ArticleFormService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    private modalService: NgbModal,
    private apiCms: CmsControllerService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ article }) => {
      this.article = article;
      if (article) {
        this.html = article.html;
        this.updateForm(article);
        this.imageUrl = article.image;
      }
    });
  }

  FadeInAnimation(FadeInContent) {
    this.modalService.open(FadeInContent, { windowClass: 'animated FadeIn' });
  }

  // convenience getter for easy access to form fields
  get fArticle() { return this.editForm.controls; }


  previousState(): void {
    window.history.back();
  }

  onFileImageSelect(file: FileList) {
    this.isChangeImage = true;
    this.anhTinTuc = file.item(0);
    //show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    if (this.anhTinTuc && this.anhTinTuc != null) {
      reader.readAsDataURL(this.anhTinTuc);
    } else {
      this.imageUrl = null;
    }
  }




  async save() {
    if(this.editForm.invalid){
      alert('Please complete all requirements before submit!')
      return;
    }
    try {
      this.isSaving = true;
      const article = this.articleFormService.getArticle(this.editForm);
      if (this.isChangeImage) {
        const imageObject = {
          loaiAnh: 2,
          ten: this.anhTinTuc.name,
          thuMuc: 'images',
          tenFile: this.anhTinTuc.name,
          nguoiTao: "admin",
          trangThai: 1
        }
        let res = await firstValueFrom(this.apiCms.createImageCms(imageObject));
        let anhId = res.result.uuid;
        let res2 = await firstValueFrom(this.apiCms.updateImage(anhId, this.anhTinTuc, 0));

        this.isChangeImage = false;
        this.imageUrl = '/images/' + res2.result.tenFile;
      }
      article.image = this.imageUrl;
      if (article.id !== null) {
        this.subscribeToSaveResponse(this.articleService.update(article));
      } else {
        this.subscribeToSaveResponse(this.articleService.create(article));
      }
    } catch (error) {
      alert('Error! Please try again or contact the administrator.');
    }
  }


  protected subscribeToSaveResponse(result: Observable<HttpResponse<IArticle>>): void {
    result.pipe(finalize(() => this.onSaveFinalize())).subscribe({
      next: (data) => this.onSaveSuccess(data),
      error: () => this.onSaveError(),
    });
  }

  protected onSaveSuccess(data: any): void {
    // if (data && data.body && data.body.id) {
      this.router.navigateByUrl('/article/' + data.body.id + '/view');
    // } else {
      // this.previousState();
    // }
  }

  protected onSaveError(): void {
    alert('Error!');
    // Api for inheritance.
  }

  protected onSaveFinalize(): void {
    this.isSaving = false;
  }

  protected updateForm(article: IArticle): void {
    this.article = article;
    this.articleFormService.resetForm(this.editForm, article);
  }
}
