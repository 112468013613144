<footer id="footer" class="footer footer-static footer-light navbar-border navbar-shadow" *ngIf="showFooter">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span
      class="float-md-left d-block d-md-inline-block">Copyright &copy; 2023 <a [routerLink]=""
        class="text-bold-800 grey darken-2" href="#" target="_blank">Reprodutiva </a></span>
    <span id="scroll-top"></span>
  </p>
</footer>

<footer id="footer" class="footer fixed-bottom footer-dark navbar-border navbar-shadow" *ngIf="!showFooter">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span
      class="float-md-left d-block d-md-inline-block">Copyright &copy; 2023 <a [routerLink]=""
        class="text-bold-800 grey darken-2" href="#" target="_blank">Reprodutiva</a></span>
    <span id="scroll-top"></span>
  </p>
</footer>