// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
    items: []
  },
  vertical_menu: {
    items: [
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: '/dashboard/dashboard1',
      },
      {
        title: 'User Management',
        icon: 'la-users',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Admin',
              page: '/admin'
            },
            {
              title: 'Moderator',
              page: '/moderator'
            },
            {
              title: 'Users',
              page: '/user'
            },
          ]
        }
      },
      {
        title: 'Article',
        icon: 'la-newspaper-o',
        page: '/article'
      },
      // {
      //   title: 'Conversation Data',
      //   icon: 'la-wechat',
      //   page: '/chat'
      // },
      // {
      //   title: 'Changelog',
      //   icon: 'la-file',
      //   page: '/changelog',
      //   // badge: { type: 'badge-danger', value: '1.0.0' },
      // },
        
    ]
  }

};





