<editor [id]="id" [disabled]="isDisabled" [init]="tinymceInitp" (onInit)="onEditorInit($event)" (onChange)="onEditorChange($event)"></editor>
<ng-template #selectImageModal let-modal>
   <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Uploaded Image</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <ul class="nav nav-tabs">
         <li (click)="changeTab(0)" class="nav-item">
            <span class="nav-link  pointer {{tab==0 ? 'active' :''}}">Uploaded image</span>
         </li>
         <li (click)="changeTab(1)" class="nav-item ">
            <span class="nav-link pointer {{tab==1 ? 'active' :''}}" href="#">Upload</span>
         </li>
      </ul>
      <div class="tab-content pt-1" id="nav-tabContent" >
         <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <div *ngIf="tab==0">
               <div class="row">
                  <div class="col">
                     <form class="form-inline">
                        <label class="sr-only">Search</label>
                        <input type="text" name="moTa" placeholder="Type something to search"
                           class="form-control mb-2 mr-sm-2" placeholder="Search" [(ngModel)]="cmsAnhFindParms.moTa">
                        <button type="button" class="btn btn-primary mb-2" (click)="search()">Search</button>
                     </form>
                  </div>
               </div>
               <div class="row">
                  <div class="col-lg-3 col-md-4 col-6" *ngFor="let cmsAnh of cmsAnhList">
                     <a class="d-block mb-3"
                        (click)="insertImage('/images/' + cmsAnh.tenFile)">
                        <img class="img-fluid img-thumbnail" [src]="'/images/' + cmsAnh.tenFile" 
                           [alt]="cmsAnh.moTa">
                     </a>
                  </div>
                  <div class="col-lg-12 text-center">
                     <span *ngIf="(start > 10)" (click)="prePage()" class="btn-pagination">
                        << </span>
                           <span *ngIf="(start +10 < total)" (click)="nextPage()" class="btn-pagination">
                              >>
                           </span>
                  </div>
               </div>
            </div>
            <form *ngIf="tab==1" [formGroup]="uploadForm">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="form-group">
                        <label for="avatar">Path </label>
                        <input formControlName="anhUrl" (change)="onFileImageSelect($event.target.files)"
                           id="file-input" type="file" #Image accept="image/*" class="form-control" name="anhUrl">
                        <div *ngIf="submitted && !imageUrl" class="invalid-feedback-custom">
                           Path is required
                        </div>
                        <div class="col-sm-12 mt-5 text-center">
                           <img *ngIf="imageUrl" src="{{imageUrl}}" style="width: 50%;">
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-12">
                     <div class="form-group">
                        <label for="moTaAnh">Description</label>
                        <textarea class="form-control" name="moTaAnh" formControlName="moTaAnh" id="moTaAnh"
                           rows="3"></textarea>
                        <div *ngIf="submitted && fUpload.moTaAnh.errors" class="invalid-feedback-custom">
                           <div *ngIf="fUpload.moTaAnh.errors.maxlength">Description is not more than 400 character
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="text-center col-sm-12">
                     <button (click)="UploadImage()" class="button button-primary">Upload</button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</ng-template>
