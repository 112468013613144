import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})

export class BaseHttpService {

  constructor(private http: HttpClient, private errorService: ErrorService) { }
  list = ["/api/cms/cms_tinbai", "/api/cms/vi/cms_tinbai", "/api/cms/en/cms_tinbai", "/api/run/run_giaichay",
    "/api/run/vi/run_giaichay", "/api/run/run_giaichay", "/api/run/vi/run_giaichay", "/api/run/en/run_giaichay",
    "/api/run/run_loaidonggop", "/api/run/vi/run_loaidonggop", "/api/run/en/run_loaidonggop", "/api/run/run_loaihoatdong",
    "/api/run/vi/run_loaihoatdong", "/api/run/en/run_loaihoatdong", "/api/run/run_giaichay_loaihoatdong", "/api/run/run_sukien",
    "/api/run/vi/run_sukien", "/api/run/en/run_sukien", "/api/run/run_doi", "/api/run/run_doi_giaichay", "/api/run/run_doi_sukien",
    "/api/run/run_donggop", "/api/sys/sys_maquocgia", "/api/sys/run_quatang_chuongtrinh", "/api/sys/sys_phienban", "/api/sys/sms",
    "/api/sys/email", "/api/sys/confirmation", "/api/auth/login", "/api/auth/create_user", "/api/auth/strava_athlete_info"];

  getHeader(): HttpHeaders {
    var headers = {
      'Content-Type': 'application/json',

    };
    // if (localStorage) {
    //   var AuthorizationBack = localStorage.getItem("AuthorizationBack");
    //   if (AuthorizationBack) {
    //     headers['AuthorizationBack'] = AuthorizationBack;
    //   }
    // }
    return new HttpHeaders(headers);
  };
  handleError(error) {
    this.errorService.nextError(error);
    return throwError(error);
  };


  get<T>(url: string, params: any): Observable<T> {
    var qs = "";
    if (params) {
      qs = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');
      qs = '?' + qs;
    }
    this.errorService.nextError(null);
    var httpOptions: Object = {
      headers: {
        "Content-Type": "application/json"
      },
      reportProgress: true,
      withCredentials: true
    }
    return this.http.get<T>(url + qs, httpOptions).pipe(map((response: any) => response.body), catchError(error => this.handleError(error)));


  }

  postImage<T>(url: string, body: any): Observable<T> {
    this.errorService.nextError(null);
    var httpOptions: Object = {
      headers: {
      },
      reportProgress: true,
      withCredentials: true
    };
    return this.http.post<T>(url, body, httpOptions).pipe(catchError(error => this.handleError(error)));
  }



  putImage<T>(url: string, body: any): Observable<T> {
    this.errorService.nextError(null);
    var httpOptions: Object = {
      headers: this.getHeader(),
      withCredentials: true
    };
    return this.http.put<T>("/api/file" + url, body, httpOptions).pipe(catchError(error => { throw (error) }));
  }

  postGetWay<T>(body): Observable<T> {
    this.errorService.nextError(null);
    var httpOptions: Object = {
      headers: {
        "Content-Type": "application/json"
      },
      reportProgress: true,
      withCredentials: true
    }
    return this.http.post<T>(`/bidcrunapiapp/global/vn/bidvrun/forward/v1`, body, httpOptions).pipe(map((response: any) => response.body), catchError(error => this.handleError(error)));
  }

  put<T>(url: string, body: any): Observable<T> {
    this.errorService.nextError(null);
    var httpOptions: Object = {
      headers: {
        "Content-Type": "application/json"
      },
      reportProgress: true,
      withCredentials: true
    }

    return this.http.put<T>(url, body, httpOptions).pipe(map((response: any) => response.body), catchError(error => this.handleError(error)));
  }





}
