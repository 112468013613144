import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { ChangelogComponent } from './changelog';
import { ArticleViewComponent } from './content/article/view/article-view.component';
import { LoginComponent } from './login';

const appRoutes: Routes = [
  {
    path: 'public-article',
    component: ArticleViewComponent
  },
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: '', component: LoginComponent }
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
      { path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard] },
      {
        path: 'account',
        loadChildren: () => import('../app/content/account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard', loadChildren: () => import('../app/content/dashboard/dashboard.module').then(m => m.DashboardModule)
        , canActivate: [AuthGuard]
      },
      // {
      //   path: 'user', loadChildren: () => import('../app/content/pages/user/user.module').then(m => m.UserModule)
      //   , canActivate: [AuthGuard]
      // },
      {
        path: 'admin', loadChildren: () => import('./content/user-management/admin/admin.module').then(m => m.AdminModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'moderator', loadChildren: () => import('./content/user-management/moderator/moderator.module').then(m => m.ModeratorModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'user', loadChildren: () => import('./content/user-management/matrix-user/matrix-user.module').then(m => m.MatrixUserModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'article', loadChildren: () => import('./content/article/article.module').then(m => m.ArticleModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'chat', loadChildren: () => import('./content/chat/groupchat/chatdata.module').then(m => m.ChatModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'chartjs', loadChildren: () => import('../app/content/charts-maps/chartjs/chartjs.module').then(m => m.ChartjsModule)
        , canActivate: [AuthGuard]
      },
      {
        path: 'boostraptables', loadChildren: () => import('../app/content/table/boostraptables/boostraptables.module').then(m =>
          m.BoostraptablesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'datatables', loadChildren: () => import('../app/content/table/datatables/datatables.module').then(m => m.DatatablesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'datatablesext', loadChildren: () => import('../app/content/table/datatablesext/datatablesext.module').then(m =>
          m.DatatablesextModule),
        canActivate: [AuthGuard]
      },
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'dashboard' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' });
