import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import dayjs from 'dayjs/esm';
import { DATE_TIME_FORMAT } from 'src/app/config/input.constants';
import { IArticle, NewArticle } from '../article.model';

/**
 * A partial Type with required key is used as form input.
 */
type PartialWithRequiredKeyOf<T extends { id: unknown }> = Partial<Omit<T, 'id'>> & { id: T['id'] };

/**
 * Type for createFormGroup and resetForm argument.
 * It accepts IArticle for edit and NewArticleFormGroupInput for create.
 */
type ArticleFormGroupInput = IArticle | PartialWithRequiredKeyOf<NewArticle>;

/**
 * Type that converts some properties for forms.
 */
type FormValueOf<T extends IArticle | NewArticle> = Omit<T, 'publishedDate' | 'createdDate' | 'modifiedDate'> & {
  publishedDate?: string | null;
  createdDate?: string | null;
  modifiedDate?: string | null;
};

type ArticleFormRawValue = FormValueOf<IArticle>;

type NewArticleFormRawValue = FormValueOf<NewArticle>;

type ArticleFormDefaults = Pick<NewArticle, 'id' | 'published' | 'publishedDate' | 'createdDate' | 'modifiedDate'>;

type ArticleFormGroupContent = {
  id: FormControl<ArticleFormRawValue['id'] | NewArticle['id']>;
  title: FormControl<ArticleFormRawValue['title']>;
  description: FormControl<ArticleFormRawValue['description']>;
  image: FormControl<ArticleFormRawValue['image']>;
  html: FormControl<ArticleFormRawValue['html']>;
  published: FormControl<ArticleFormRawValue['published']>;
  publishedBy: FormControl<ArticleFormRawValue['publishedBy']>;
  publishedDate: FormControl<ArticleFormRawValue['publishedDate']>;
  createdBy: FormControl<ArticleFormRawValue['createdBy']>;
  createdDate: FormControl<ArticleFormRawValue['createdDate']>;
  modifiedBy: FormControl<ArticleFormRawValue['modifiedBy']>;
  modifiedDate: FormControl<ArticleFormRawValue['modifiedDate']>;
};

export type ArticleFormGroup = FormGroup<ArticleFormGroupContent>;

@Injectable({ providedIn: 'root' })
export class ArticleFormService {
  createArticleFormGroup(article: ArticleFormGroupInput = { id: null }): ArticleFormGroup {
    const articleRawValue = this.convertArticleToArticleRawValue({
      ...this.getFormDefaults(),
      ...article,
    });
    return new FormGroup<ArticleFormGroupContent>({
      id: new FormControl(
        { value: articleRawValue.id, disabled: true },
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      title: new FormControl(articleRawValue.title, {
        validators: [Validators.required, Validators.maxLength(255)],
      }),
      description: new FormControl(articleRawValue.description, {
        validators: [Validators.maxLength(500)],
      }),
      image: new FormControl(articleRawValue.image, {
        validators: [Validators.maxLength(500)],
      }),
      html: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(65000)],
      }),
      published: new FormControl(articleRawValue.published),
      publishedBy: new FormControl(articleRawValue.publishedBy, {
        validators: [Validators.maxLength(100)],
      }),
      publishedDate: new FormControl(articleRawValue.publishedDate),
      createdBy: new FormControl(articleRawValue.createdBy, {
        validators: [Validators.maxLength(100)],
      }),
      createdDate: new FormControl(articleRawValue.createdDate),
      modifiedBy: new FormControl(articleRawValue.modifiedBy, {
        validators: [Validators.maxLength(100)],
      }),
      modifiedDate: new FormControl(articleRawValue.modifiedDate),
    });
  }

  getArticle(form: ArticleFormGroup): IArticle | NewArticle {
    return this.convertArticleRawValueToArticle(form.getRawValue() as ArticleFormRawValue | NewArticleFormRawValue);
  }

  resetForm(form: ArticleFormGroup, article: ArticleFormGroupInput): void {
    const articleRawValue = this.convertArticleToArticleRawValue({ ...this.getFormDefaults(), ...article });
    form.reset(
      {
        ...articleRawValue,
        id: { value: articleRawValue.id, disabled: true },
      } as any /* cast to workaround https://github.com/angular/angular/issues/46458 */
    );
  }

  private getFormDefaults(): ArticleFormDefaults {
    const currentTime = dayjs();

    return {
      id: null,
      published: false,
      publishedDate: currentTime,
      createdDate: currentTime,
      modifiedDate: currentTime,
    };
  }

  private convertArticleRawValueToArticle(rawArticle: ArticleFormRawValue | NewArticleFormRawValue): IArticle | NewArticle {
    return {
      ...rawArticle,
      publishedDate: dayjs(rawArticle.publishedDate, DATE_TIME_FORMAT),
      createdDate: dayjs(rawArticle.createdDate, DATE_TIME_FORMAT),
      modifiedDate: dayjs(rawArticle.modifiedDate, DATE_TIME_FORMAT),
    };
  }

  private convertArticleToArticleRawValue(
    article: IArticle | (Partial<NewArticle> & ArticleFormDefaults)
  ): ArticleFormRawValue | PartialWithRequiredKeyOf<NewArticleFormRawValue> {
    return {
      ...article,
      publishedDate: article.publishedDate ? article.publishedDate.format(DATE_TIME_FORMAT) : undefined,
      createdDate: article.createdDate ? article.createdDate.format(DATE_TIME_FORMAT) : undefined,
      modifiedDate: article.modifiedDate ? article.modifiedDate.format(DATE_TIME_FORMAT) : undefined,
    };
  }
}
